/* @flow strict */

import { Link } from 'react-router-dom';
import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const StyledCard: StyledComponent<Props, Theme, *> = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 640px;
  min-height: 128px;
  box-shadow: ${({ theme }) => theme.shadows['1']};
  background-color: ${({ theme }) => theme.palette.background.card};

  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};

  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus {
    transform: scale(1.01);
  }

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};
    }
    `;
  }};
`;

export const ImageWrapper: StyledComponent<Props, Theme, *> = styled.div`
  width: 64px;
  height: 64px;
  margin-right: ${({ theme }) => theme.spacing(3)};

  img {
    border-radius: 9999px;
  }
`;

export const CardSection: StyledComponent<Props, Theme, *> = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledLink: StyledComponent<Props, Theme, *> = styled(Link)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 640px;
  min-height: 128px;

  color: inherit;
  text-decoration: inherit;
  user-select: text;
`;
