/* @flow strict */

import { useEffect, useRef, useState } from 'react';
import type { LocationShape } from 'react-router-dom';

import { imgix } from 'utils/imgix';
import { Routes } from 'utils/routes';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(initialState: boolean): {
  ref: { current: null },
  isOutside: boolean,
  setIsOutside: (((boolean) => boolean) | boolean) => void,
} {
  const [isOutside, setIsOutside] = useState(initialState);
  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOutside(true);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return { ref, isOutside, setIsOutside };
}

/*
 * Returns pathname
 */
export function getPathname(pathname: string): string {
  let currentPath = pathname;

  // Portfolio paths ignore the rest of the URL
  if (pathname.startsWith(Routes.portfolio)) {
    currentPath = Routes.portfolio;
  }

  return currentPath;
}

/*
 * Set image fallback if image URL has an error.
 * First fallback: WebP, second fallback: png/jpg
 */
export function handleImageError(
  e: SyntheticEvent<HTMLImageElement>,
  imageUrl: string,
  imageWebP?: string,
  imageSrc: string
): void {
  if (!(e.target instanceof window.HTMLImageElement)) {
    return;
  }

  if (imageWebP == null) {
    if (e.target.src === imageUrl) {
      e.target.src = imageSrc;
      e.target.srcset = imageSrc;
    }
  } else {
    if (e.target.src === imageUrl) {
      e.target.src = imageWebP;
      e.target.srcset = imageWebP;
    }
    if (e.target.src === imageWebP) {
      e.target.src = imageSrc;
      e.target.srcset = imageSrc;
    }
  }
}

/*
 * Returns the image path with the given width and height
 */
export const getImagePath = (
  folderPath: string,
  file: string,
  width: number,
  height: number
): string => {
  return `${imgix}${folderPath}/${file}?w=${width}&h=${height}&fit=crop&auto=format`;
};

export type Image = {
  url: string,
  src: string,
  webp?: string,
  alt: string,
  caption?: string,
  type?: string,
  to?: string | LocationShape,
};

export type LinkIcon = {
  url: string,
  src: string,
  name: string,
  alt: string,
  to: string | LocationShape,
  width?: number,
};
