/* @flow strict */

// Link theme file with material theme

import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

import * as theme from './theme';

// eslint-disable-next-line flowtype/no-weak-types
const baseTheme: any = createTheme({
  palette: {
    primary: {
      main: theme.theme.palette.primary.light,
    },
    text: {
      primary: theme.theme.palette.text.primary,
    },
    background: {
      default: theme.theme.palette.background.default,
    },
  },
  typography: {
    fontFamily: theme.fontFamily,
    fontWeightLight: theme.fontWeightLight,
    fontWeightRegular: theme.fontWeightRegular,
    fontWeightMedium: theme.fontWeightMedium,
    fontWeightBold: theme.fontWeightBold,
    h1: theme.theme.typography.h1,
    h2: theme.theme.typography.h2,
    h3: theme.theme.typography.h3,
    h4: theme.theme.typography.h4,
    h5: theme.theme.typography.h5,
    h6: theme.theme.typography.h6,
    body1: theme.theme.typography.bodyLong1,
    body2: theme.theme.typography.bodyShort1,
    caption: theme.theme.typography.caption,
    button: {
      fontFamily: theme.fontFamily,
      fontWeight: theme.fontWeightMedium,
      fontSize: theme.theme.typography.bodyLong1.fontSize,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      outlinedPrimary: {
        '&:focus': {
          border: '1px solid rgb(0 102 255)',
          backgroundColor: 'rgb(0 102 255 / 4%)',
        },
      },
      containedPrimary: {
        '&:focus': {
          color: '#fff',
          backgroundColor: theme.theme.palette.primary.lightHover,
        },
      },
    },
  },
});

// eslint-disable-next-line flowtype/no-weak-types
export const materialTheme: any = responsiveFontSizes(baseTheme);
