/* @flow strict */

import * as React from 'react';

import { ChevronWrapper, ChevronDown, ChevronUp } from './styles';

export type Direction = 'up' | 'down';

type Props = {
  direction: Direction,
  onClick: (id: string) => void,
};

export const Chevron = ({
  direction,
  onClick,
}: Props): null | React.Element<typeof ChevronWrapper> => {
  if (direction === 'down') {
    return (
      <ChevronWrapper
        role='button'
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <ChevronDown />
      </ChevronWrapper>
    );
  }

  if (direction === 'up') {
    return (
      <ChevronWrapper
        role='button'
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <ChevronUp />
      </ChevronWrapper>
    );
  }

  return null;
};
