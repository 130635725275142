/* @flow strict */

// Typography
export const fontFamily: string = [
  '"Quicksand"',
  '"Helvetica"',
  '"Arial"',
  'sans-serif',
].join(',');
const fontSize = 16;
const htmlFontSize = 16;
const coef = fontSize / htmlFontSize;
export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 700;
export const pxToRem = (size: number): string =>
  `${(size / htmlFontSize) * coef}rem`;

// Spacing
const spacing = (multiplier: number = 1) => {
  if (isNaN(multiplier)) {
    throw new Error(
      `Expected spacing multiplier to be a number, got '${multiplier}'`
    );
  }
  return `${8 * multiplier}px`;
};

// Breakpoints
const defaultBreakpoints = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

type Breakpoints = {
  sm: string,
  md: string,
  lg: string,
  xl: string,
};
type Breakpoint = $Keys<typeof defaultBreakpoints>;

const up = (key: Breakpoint, breakpoints: Breakpoints = defaultBreakpoints) => {
  const value = breakpoints[key];
  return `@media (min-width: ${value})`;
};
const down = (
  key: Breakpoint,
  breakpoints: Breakpoints = defaultBreakpoints
) => {
  const value = breakpoints[key];
  if (value.slice(-2) === 'px') {
    const pxValue = Number(value.slice(0, -2));
    return `@media (max-width: ${pxValue - 5 / 100}px)`;
  }
  const pxValue = Number(value);
  return `@media (max-width: ${pxValue - 5 / 100})`;
};

type ColorPalette = {
  primary?: string,
  disabled?: string,
  light?: string,
  lightHover?: string,
  visited?: string,
  default?: string,
  card?: string,
  overlay?: string,
};

export type Theme = {
  palette: {
    [key: string]: ColorPalette,
  },
  shadows: {
    [key: string]: string,
  },
  spacing: (multiplier?: number) => string,
  typography: {
    fontFamily: string,
    fontWeightLight: number,
    fontWeightRegular: number,
    fontWeightMedium: number,
    fontWeightBold: number,
    [key: string]: {
      fontFamily: string,
      fontWeight: number,
      fontSize: string,
      lineHeight: number,
      letterSpacing: string,
    },
  },
  zIndex: {
    [key: string]: number,
  },
  breakpoints: {
    values: Breakpoints,
    up: (key: Breakpoint, breakpoints?: Breakpoints) => string,
    down: (key: Breakpoint, breakpoints?: Breakpoints) => string,
  },
};

export const theme: Theme = {
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    primary: {
      light: '#0066ff',
      lightHover: '#0047b2',
    },
    secondary: {
      light: '#f50057',
      lightHover: '#c51162',
    },
    background: {
      default: '#fdfdfd',
      card: '#ffffff',
      overlay: '#333333',
    },
    error: {
      light: '#f44336',
    },
    warning: {
      light: '#ff9800',
    },
    success: {
      light: '#4caf50',
    },
    link: {
      light: '#f50057',
      lightHover: '#c51162',
      visited: '#c51162',
    },
  },
  shadows: {
    '0': 'none',
    '1': '0px 1px 4px rgba(0,0,0,0.1)',
    '2': '0px 2px 10px rgba(0,0,0,0.1)',
    '3': '0px 4px 8px rgba(0,0,0,0.1)',
    '4': '0px 4px 16px rgba(0,0,0,0.1)',
    '5': '0px 12px 24px rgba(0,0,0,0.1)',
    '6': '0px 16px 32px rgba(0,0,0,0.1)',
  },
  spacing: spacing,
  typography: {
    fontFamily: fontFamily,
    fontWeightLight: fontWeightLight,
    fontWeightRegular: fontWeightRegular,
    fontWeightMedium: fontWeightMedium,
    fontWeightBold: fontWeightBold,
    h1: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(54): string),
      lineHeight: 1.2, // 64px
      letterSpacing: '0em',
    },
    h2: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(42): string),
      lineHeight: 1.2, // 50px
      letterSpacing: '0em',
    },
    h3: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(32): string),
      lineHeight: 1.25, // 40px
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(28): string),
      lineHeight: 1.3, // 36px
      letterSpacing: '0em',
    },
    h5: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(20): string),
      lineHeight: 1.4, // 28px
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: (pxToRem(16): string),
      lineHeight: 1.4, // 22px
      letterSpacing: '0em',
    },
    bodyShort1: {
      fontFamily: fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: (pxToRem(16): string),
      lineHeight: 1.4, // 22px for short
      letterSpacing: '0em',
    },
    bodyLong1: {
      fontFamily: fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: (pxToRem(16): string),
      lineHeight: 1.5, // 24px for long
      letterSpacing: '0em',
    },
    caption: {
      fontFamily: fontFamily,
      fontWeight: fontWeightRegular,
      fontSize: (pxToRem(14): string),
      lineHeight: 1.3, // 18px
      letterSpacing: '0em',
    },
  },
  zIndex: {
    navBar: 1100,
    drawer: 1200,
    dialog: 1300,
    tooltip: 1500,
  },
  breakpoints: {
    values: defaultBreakpoints,
    up: up,
    down: down,
  },
};
