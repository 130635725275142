/* @flow strict */

import * as React from 'react';

import { SpinnerRing, SpinnerSrText } from './styles';

export const Spinner = (): React.Element<typeof React.Fragment> => {
  return (
    <>
      <SpinnerRing className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </SpinnerRing>
      <SpinnerSrText>Loading</SpinnerSrText>
    </>
  );
};
