/* @flow strict */

import * as React from 'react';

import { handleImageError } from 'utils/common';

type Props = {
  alt: string,
  height: number,
  width: number,
  url: string,
  webp?: string,
  src: string,
};

export const Image = ({
  alt,
  height,
  width,
  url,
  webp,
  src,
}: Props): React.Element<'img'> => {
  return (
    <img
      alt={alt}
      height={height}
      src={url}
      srcSet={`${url}&dpr=1 1x, ${url}&q=40&dpr=2 2x, ${url}&q=20&dpr=3 3x`}
      width={width}
      onError={(e) => handleImageError(e, url, webp, src)}
    />
  );
};
