/* @flow strict */

import MaterialDialogContent from '@material-ui/core/DialogContent';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const CardContainer: StyledComponent<Props, Theme, *> = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: ${({ theme }) => theme.spacing(1)};
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  padding: 0;
  box-sizing: border-box;

  margin-bottom: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('md')} {
      grid-template-columns: repeat(3, minmax(240px, 1fr));
    }
    `;
  }};
`;

type CardProps = {
  rows?: number,
};

export const Card: StyledComponent<CardProps, Theme, *> = styled.div`
  position: relative;
  padding: 0;
  overflow: hidden;
  grid-row-end: span ${({ rows }) => rows ?? 6};
  box-shadow: ${({ theme }) => theme.shadows['1']};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.01);
  }
`;

export const CardLink: StyledComponent<CardProps, Theme, *> = styled(Link)`
  &,
  & > * {
    transition: all 0.2s ease-in-out;
  }

  & img,
  & svg {
    color: black;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  & p {
    position: absolute;
    padding: ${({ theme }) => theme.spacing(2)};
    bottom: 0;
    left: 0;
    right: 0;
    color: ${({ theme }) => theme.palette.background.default};
    background-color: ${({ theme }) => theme.palette.background.overlay};
    opacity: 0;
  }

  &:hover,
  &:focus {
    outline: none;

    & img,
    & svg {
      opacity: 0.3;
    }

    & p {
      opacity: 0.7;
    }
  }
`;

export const ProjectLogo: StyledComponent<Props, Theme, *> = styled.picture`
  & img {
    height: ${({ theme }) => theme.spacing(3)};
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const DialogTitle: StyledComponent<Props, Theme, *> = styled(
  MaterialDialogTitle
)`
  & > *,
  & picture {
    display: flex;
    align-items: center;
  }
`;

export const DialogContent: StyledComponent<Props, Theme, *> = styled(
  MaterialDialogContent
)`
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  & > *:last-child {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .date {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .gallery {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Gallery: StyledComponent<Props, Theme, *> = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;

  & > div {
    margin: ${({ theme }) => theme.spacing(2)};
  }

  & > div p {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  & img,
  & video {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    box-shadow: ${({ theme }) => theme.shadows['1']};
  }
`;
