/* @flow strict */

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Container } from 'components/Container';
import { Image } from 'components/Image';
import { axios } from 'utils/axios';
import { PageHeader } from 'utils/styles';

import {
  sellingPoints,
  howItWorks,
  introductionImg,
  closingImg,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from './contents';
import { Content, Split, ButtonWrapper } from './styles';

const CONTAINER_MODULE = 'services';
type State = 'success' | 'failure' | null;
type Fields = 'name' | 'email' | 'company' | 'description';

type FormField = {
  fullWidth?: boolean,
  id: string,
  label: string,
  maxRows?: number,
  minRows?: number,
  multiline?: boolean,
  onChange: (e: empty) => void,
  required?: boolean,
  value: string,
  type?: string,
};

export const Services = (): React.Element<typeof Container> => {
  const contactRef = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<State>(null);

  useEffect(() => {
    // Decode entities in the URL
    // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 1 && hashParts.slice(-1)[0] === 'contact') {
        setTimeout(() => {
          window.scrollTo({
            top: contactRef.current?.offsetTop,
            behavior: 'smooth',
          });
        }, 0);
      }
    };

    scrollToAnchor();
  }, []);

  function handleChange(event, type: Fields): void {
    const value = event.target.value;

    switch (type) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'company':
        setCompany(value);
        break;
      case 'description':
        setDescription(value);
        break;
    }
  }

  function handleSubmitForm(event): void {
    event.preventDefault();
    setIsLoading(true);

    axios
      .post(
        '/send-email',
        JSON.stringify({
          name,
          email,
          company,
          description,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        setName('');
        setEmail('');
        setCompany('');
        setDescription('');
        setIsLoading(false);
        setShowMessage('success');
      })
      .catch(() => {
        setIsLoading(false);
        setShowMessage('failure');
      });
  }

  const formFields: Array<FormField> = [
    {
      id: 'name',
      label: 'Name',
      required: true,
      value: name,
      onChange: (e) => handleChange(e, 'name'),
    },
    {
      id: 'email',
      label: 'Email',
      required: true,
      type: 'email',
      value: email,
      onChange: (e) => handleChange(e, 'email'),
    },
    {
      id: 'company',
      label: 'Company',
      value: company,
      onChange: (e) => handleChange(e, 'company'),
    },
    {
      id: 'description',
      label: 'Description',
      required: true,
      fullWidth: true,
      multiline: true,
      minRows: 5,
      maxRows: 5,
      value: description,
      onChange: (e) => handleChange(e, 'description'),
    },
  ];

  return (
    <Container id={CONTAINER_MODULE}>
      <Helmet>
        <title>Professional Software Development</title>
        <meta
          content='Bring your project vision to life. Professional software development you can afford.'
          name='description'
        />
      </Helmet>
      <PageHeader>
        <Typography component='h1' variant='h4'>
          Bring Your Vision to Life
        </Typography>
        <Typography component='h2'>
          Professional Software Development You Can Afford
        </Typography>
      </PageHeader>

      <Content>
        <div className='section'>
          <Split>
            <Typography>
              High-quality software and web development isn’t cheap, and cheap
              development is rarely high-quality. For small businesses around
              the world, there seems to be no middle-ground when it comes to
              creating and optimizing software of their own. I’m here to change
              that.
            </Typography>

            <Image
              alt={introductionImg.alt}
              height={IMAGE_HEIGHT}
              src={introductionImg.src}
              url={introductionImg.url}
              webp={introductionImg.webp}
              width={IMAGE_WIDTH}
            />
          </Split>
        </div>

        <div className='section'>
          <Typography className='header' component='h2' variant='h4'>
            How I Can Help You
          </Typography>

          {sellingPoints.map((point) => (
            <div key={point.title} className='point'>
              <Split>
                <Image
                  alt={point.img.alt}
                  height={IMAGE_HEIGHT}
                  src={point.img.src}
                  url={point.img.url}
                  webp={point.img.webp}
                  width={IMAGE_WIDTH}
                />

                <div>
                  <Typography className='title' component='h3' variant='h5'>
                    {point.title}
                  </Typography>
                  <Typography>{point.description}</Typography>
                </div>
              </Split>
            </div>
          ))}
        </div>

        <div className='section'>
          <Typography className='header' component='h2' variant='h4'>
            How It Works
          </Typography>

          <Typography>
            I know that you value your time as much as I do my own, which is why
            I keep my process as simple as possible.
          </Typography>

          <ol>
            {howItWorks.map((point) => (
              <li key={point.title} className='point'>
                <Typography component='h3' variant='h5'>
                  {point.title}
                </Typography>
                <Typography>{point.description}</Typography>
              </li>
            ))}
          </ol>
        </div>

        <div className='section'>
          <Typography className='header' component='h2' variant='h4'>
            What’s Yours Stays Yours
          </Typography>

          <Split>
            <Typography>
              Unlike so many of my competitors, my software development services
              don’t include monthly maintenance fees. Your assets remain 100%
              your own throughout and after our journey together.
            </Typography>

            <Image
              alt={closingImg.alt}
              height={IMAGE_HEIGHT}
              src={closingImg.src}
              url={closingImg.url}
              webp={closingImg.webp}
              width={IMAGE_WIDTH}
            />
          </Split>
        </div>

        <div className='section'>
          <Typography
            ref={contactRef}
            className='header'
            component='h2'
            variant='h4'
          >
            Let’s Talk
          </Typography>

          <Typography>
            You can book an obligation-free consultation with me right now to
            discuss what you need, how I can help you, and what we can do to
            bring your vision to life.
          </Typography>

          <form onSubmit={handleSubmitForm}>
            {formFields.map((field) => (
              <TextField
                key={field.id}
                disabled={isLoading}
                fullWidth={field.fullWidth}
                id={field.id}
                label={field.label}
                margin='normal'
                maxRows={field.maxRows ?? 1}
                minRows={field.minRows ?? 1}
                multiline={field.multiline}
                required={field.required}
                type={field.type ?? 'text'}
                value={field.value}
                variant='outlined'
                onChange={field.onChange}
              />
            ))}

            {showMessage === 'success' && (
              <FormHelperText style={{ width: '100%' }}>
                Thank you for contacting me. I will be contacting you within 3
                days.
              </FormHelperText>
            )}

            {showMessage === 'failure' && (
              <FormHelperText error style={{ width: '100%' }}>
                Something went wrong. Please try again later.
              </FormHelperText>
            )}

            <ButtonWrapper>
              <Button
                color='primary'
                disabled={isLoading}
                type='submit'
                variant='contained'
              >
                {!isLoading && 'Submit'}
              </Button>
              {isLoading && <CircularProgress className='progress' size={24} />}
            </ButtonWrapper>
          </form>
        </div>
      </Content>
    </Container>
  );
};
