/* @flow strict */

import Typography from '@material-ui/core/Typography';
import WebSharpIcon from '@material-ui/icons/WebSharp';
import * as React from 'react';
import { Link } from 'react-router-dom';

import * as DidImages from 'assets/images/portfolio/did_2020';
import * as JiakAwayImages from 'assets/images/portfolio/jiakaway';
import * as TateAsiaImages from 'assets/images/portfolio/tateasia';
import * as VybeImages from 'assets/images/portfolio/vybe';
import * as WaterTreatmentImages from 'assets/images/portfolio/water_treatment';
import { Routes } from 'utils/routes';

type Project = {
  title: string,
  shortName: string,
  img?: Image,
  icon?: React.Node,
  rows: number,
  logo?: Image,
  date?: string,
  metaDescription: string,
  description: React.Node,
  gallery?: Array<Image>,
  link?: string,
};

type Image = {
  src: string,
  webp: string,
  alt: string,
  caption?: string,
  type?: string,
};

export const projects: Array<Project> = [
  {
    title: 'Tate Asia',
    shortName: 'tateasia',
    img: {
      src: TateAsiaImages.Cover,
      webp: TateAsiaImages.CoverWebP,
      alt: 'Tate Asia',
    },
    rows: 16,
    date: 'December 2020 - January 2021',
    link: 'https://www.tateasia.com/',
    metaDescription:
      'Redesigned and migrated the company’s website to a low-code easy to maintain platform.',
    description: (
      <Typography component='span'>
        Redesigned and migrated the company’s website to a low-code easy to
        maintain platform.
      </Typography>
    ),
    gallery: [
      {
        src: TateAsiaImages.Cover,
        webp: TateAsiaImages.CoverWebP,
        alt: 'Mobile home page',
      },
      {
        src: TateAsiaImages.Before,
        webp: TateAsiaImages.BeforeWebP,
        alt: 'Before redesign',
        caption: 'Before redesign',
      },
      {
        src: TateAsiaImages.Landing,
        webp: TateAsiaImages.LandingWebP,
        alt: 'After redesign',
        caption: 'After redesign',
      },
    ],
  },
  {
    title: 'Vybe',
    shortName: 'vybe',
    img: {
      src: VybeImages.Cover,
      webp: VybeImages.CoverWebP,
      alt: 'Vybe',
    },
    rows: 10,
    date: 'January 2021 - February 2021',
    metaDescription:
      'Vybe is a platform built to connect people who are figuring out their career paths with mentors in their industry.',
    description: (
      <Typography component='span'>
        Vybe is a platform built to connect people who are figuring out their
        career paths with mentors in their industry.
      </Typography>
    ),
    gallery: [
      {
        src: VybeImages.Cover,
        webp: VybeImages.CoverWebP,
        alt: 'Home page',
      },
      {
        src: VybeImages.Services,
        webp: VybeImages.ServicesWebP,
        alt: 'Services',
      },
    ],
  },
  {
    title: 'NUS DID Graduation Show 2020',
    shortName: 'did-2020',
    img: {
      src: DidImages.Cover,
      webp: DidImages.CoverWebP,
      alt: 'DID 2020',
    },
    rows: 8,
    logo: {
      src: DidImages.Logo,
      webp: DidImages.LogoWebP,
      alt: 'DID 2020',
    },
    date: 'May 2020 - June 2020',
    link: 'https://www.sde.nus.edu.sg/did/gradshows/2020/#/',
    metaDescription:
      'The annual NUS Division of Industrial Design (DID) graduation show features projects done by the graduates of the Bachelor of Arts in Industrial Design. The project is comprised of a pre-launch countdown timer, project filtering, and a bookmarks page for users to bookmark their favorite designers.',
    description: (
      <Typography component='span'>
        The annual NUS Division of Industrial Design (DID) graduation show
        features projects done by the graduates of the Bachelor of Arts in
        Industrial Design. The project is comprised of a pre-launch countdown
        timer, project filtering, and a bookmarks page for users to bookmark
        their favorite designers.
      </Typography>
    ),
    gallery: [
      {
        type: 'video',
        src: DidImages.Countdown,
        webp: DidImages.CountdownWebM,
        alt: 'Countdown page',
      },
      {
        src: DidImages.Cover,
        webp: DidImages.CoverWebP,
        alt: 'Landing page',
      },
      {
        src: DidImages.Projects,
        webp: DidImages.ProjectsWebP,
        alt: 'Projects page',
      },
      {
        src: DidImages.Project,
        webp: DidImages.ProjectWebP,
        alt: 'Individual project page',
      },
      {
        src: DidImages.Bookmarks,
        webp: DidImages.BookmarksWebP,
        alt: 'Bookmarks page',
      },
    ],
  },
  {
    title: 'Jiak Away',
    shortName: 'jiakaway',
    img: {
      src: JiakAwayImages.Cover,
      webp: JiakAwayImages.CoverWebP,
      alt: 'Jiak Away',
    },
    rows: 10,
    logo: {
      src: JiakAwayImages.Logo,
      webp: JiakAwayImages.LogoWebP,
      alt: 'Jiak Away',
    },
    date: 'March 2020 - July 2020',
    link: 'https://jiakaway.com/',
    metaDescription:
      'Jiak Away is a platform that allows F&amp;B companies to set up an automated ordering process. It was built in 2020 due to COVID-19 restrictions that were imposed on dining in to help businesses that are taking in orders manually. Orders are handled and processed via the platform and the final order is received by email and Telegram notification. The project is comprised of restaurant filtering based on postal code, add to cart functionality, and an admin portal for businesses to update their restaurant information and menu.',
    description: (
      <Typography component='span'>
        Jiak Away is a platform that allows F&amp;B companies to set up an
        automated ordering process. It was built in 2020 due to COVID-19
        restrictions that were imposed on dining in to help businesses that are
        taking in orders manually. Orders are handled and processed via the
        platform and the final order is received by email and Telegram
        notification. The project is comprised of restaurant filtering based on
        postal code, add to cart functionality, and an admin portal for
        businesses to update their restaurant information and menu.
      </Typography>
    ),
    gallery: [
      {
        src: JiakAwayImages.Cover,
        webp: JiakAwayImages.CoverWebP,
        alt: 'Home page',
      },
      {
        src: JiakAwayImages.Restaurants,
        webp: JiakAwayImages.RestaurantsWebP,
        alt: 'Restaurants listing page',
      },
      {
        src: JiakAwayImages.Restaurant,
        webp: JiakAwayImages.RestaurantWebP,
        alt: 'Individual restaurant page',
      },
      {
        src: JiakAwayImages.Cart,
        webp: JiakAwayImages.CartWebP,
        alt: 'Cart page',
      },
    ],
  },
  {
    title: 'Water Treatment Company',
    shortName: 'water-treatment-company',
    img: {
      src: WaterTreatmentImages.Cover,
      webp: WaterTreatmentImages.CoverWebP,
      alt: 'Water Treatment Company',
    },
    rows: 12,
    date: 'August 2021',
    metaDescription:
      'Proposed landing page for a water treatment company’s website redesign.',
    description: (
      <Typography component='span'>
        Proposed landing page for a water treatment company’s website redesign.
      </Typography>
    ),
    gallery: [
      {
        src: WaterTreatmentImages.Cover,
        webp: WaterTreatmentImages.CoverWebP,
        alt: 'Home page',
      },
    ],
  },
  {
    title: 'Your Project Goes Here',
    shortName: 'future-project',
    icon: <WebSharpIcon />,
    rows: 8,
    metaDescription:
      'Interested in working together for a project? Let’s get in touch and bring your project to life.',
    description: (
      <Typography component='span'>
        Like what you see? Let’s{' '}
        <Link to={{ pathname: Routes.services, hash: '#contact' }}>
          get in touch
        </Link>{' '}
        and bring your project to life.
      </Typography>
    ),
  },
];
