/* @flow strict */

import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const PageHeader: StyledComponent<Props, Theme, *> = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  & > h1 + h2 {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      margin-top: ${theme.spacing(5)};
      margin-bottom: ${theme.spacing(8)};
    }
    `;
  }};
`;
