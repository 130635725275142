/* @flow strict */

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Card } from 'components/Card';
import { Chevron } from 'components/Chevron';
import { Container } from 'components/Container';
import { Image } from 'components/Image';
import { Routes } from 'utils/routes';
import { PageHeader } from 'utils/styles';

import {
  socials,
  experiences,
  skills,
  certifications,
  ICON_HEIGHT,
  ICON_WIDTH,
} from './contents';
import {
  IntroductionSection,
  MobileLineBreak,
  IconWrapper,
  Icon,
  Section,
  SectionWrapper,
  Ul,
} from './styles';

const CONTAINER_MODULE = 'aboout';

export const About = (): React.Element<typeof Container> => {
  function handleChevronClick(id: string): void {
    const doc = document.getElementById(id);
    if (doc !== null) {
      doc.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Container id={CONTAINER_MODULE}>
      <Helmet>
        <title>About</title>
        <meta
          content='Denzel Chung is a software engineer and freelance web developer based in Singapore with industry experience in large tech companies such as Meta and IBM.'
          name='description'
        />
      </Helmet>
      <IntroductionSection>
        <SectionWrapper>
          <PageHeader>
            <Typography component='h1' variant='h2'>
              Denzel Chung
            </Typography>
            <Typography component='h2'>
              Software Engineer / <MobileLineBreak />
              Freelance Web Developer
            </Typography>
          </PageHeader>

          <Button
            color='primary'
            component={Link}
            to={{ pathname: Routes.services, hash: '#contact' }}
            variant='outlined'
          >
            Get in Touch
          </Button>

          <IconWrapper>
            {socials.map((social) => (
              <div key={social.name}>
                <Link rel='noopener noreferrer' target='_blank' to={social.to}>
                  <Icon>
                    <Image
                      alt={social.alt}
                      height={ICON_HEIGHT}
                      src={social.src}
                      url={social.url}
                      width={social.width ?? ICON_WIDTH}
                    />
                  </Icon>
                </Link>
              </div>
            ))}
          </IconWrapper>
        </SectionWrapper>

        <Chevron
          direction='down'
          onClick={() => handleChevronClick('experience')}
        />
      </IntroductionSection>

      <Section id='experience'>
        <SectionWrapper>
          <Typography component='h3' variant='h4'>
            Professional Experience
          </Typography>

          {experiences.map((experience) => (
            <Card
              key={experience.date}
              alt={experience.alt}
              date={experience.date}
              img={experience.img}
              subtitle={experience.subtitle}
              title={experience.title}
              url={experience.url}
            />
          ))}
        </SectionWrapper>

        <Chevron
          direction='down'
          onClick={() => handleChevronClick('skills')}
        />
      </Section>

      <Section id='skills'>
        <SectionWrapper>
          <Typography component='h3' variant='h4'>
            Skills & Expertise
          </Typography>

          <Ul>
            {skills.map((skill) => (
              <li key={skill}>
                <Typography>{skill}</Typography>
              </li>
            ))}
          </Ul>
        </SectionWrapper>

        <Chevron
          direction='down'
          onClick={() => handleChevronClick('certifications')}
        />
      </Section>

      <Section id='certifications'>
        <SectionWrapper>
          <Typography component='h3' variant='h4'>
            Certifications
          </Typography>

          {certifications.map((cert) => (
            <Card
              key={cert.date}
              alt={cert.alt}
              className='card-link'
              credentialId={cert.credentialId}
              date={cert.date}
              href={cert.href}
              img={cert.img}
              subtitle={cert.subtitle}
              title={cert.title}
              url={cert.url}
            />
          ))}
        </SectionWrapper>

        <Chevron direction='up' onClick={scrollToTop} />
      </Section>
    </Container>
  );
};
