/* @flow strict */

import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import FlagIcon from 'assets/icons/glyphicons-basic-264-flag-waving.svg';
import LibraryIcon from 'assets/icons/glyphicons-basic-422-book-library.svg';
import BriefcaseIcon from 'assets/icons/glyphicons-basic-460-briefcase.svg';
import MoreIcon from 'assets/icons/glyphicons-basic-497-more.svg';
import ChildIcon from 'assets/icons/glyphicons-basic-533-user-child.svg';
import EducationIcon from 'assets/icons/glyphicons-basic-68-education.svg';

import { Ul, Svg } from './styles';

type Milestone = {
  title: string,
  icon: React$Node,
  date: string,
  content: React.Node,
};

export const milestones: Array<Milestone> = [
  {
    title: 'Born',
    icon: <Svg img={ChildIcon} />,
    date: '1995',
    content: (
      <Typography>
        &#34;A woman cries in the hospital. Silence, then a childs cry answers.
        Some say it was divine intervention others a cruel experiment. I am
        born.&#34; - Jamie Campbell Bower
      </Typography>
    ),
  },

  {
    title: 'Diploma - Temasek Polytechnic',
    icon: <Svg img={EducationIcon} />,
    date: '2012 - 2015',
    content: (
      <>
        <Typography>
          Graduated from Temasek Polytechnic with a Diploma in Computer
          Engineering with Merit.
        </Typography>

        <Ul>
          <li>
            <Typography>
              Director’s List Award 2014 for being among the top 10% of academic
              achievers in the academic year 2013/2014
            </Typography>
          </li>
          <li>
            <Typography>
              Gold Prize Winner for &#39;QR Lost Identifier&#39; - TP Innovation
              Competition 2014
            </Typography>
          </li>
          <li>
            <Typography>
              Commendation Award for team project &#39;Smart Living - Bringing
              the Community Closer&#39; at the Engineering Project Show 2015
            </Typography>
          </li>
          <li>
            <Typography>
              Commendation Award for &#39;Rekindle&#39; - Tan Kah Kee Young
              Inventors’ Award 2015
            </Typography>
          </li>
          <li>
            <Typography>
              Graduated with the National Instruments Singapore Prize (Best
              student of the subject &#39;Data Acquisition Systems&#39;)
            </Typography>
          </li>
          <li>
            <Typography>
              Graduated with the Inventions Prize (Best student of the subject
              &#39;Computer Architecture and Operating Systems&#39;)
            </Typography>
          </li>
        </Ul>
      </>
    ),
  },

  {
    title: 'First Publication',
    icon: <Svg img={LibraryIcon} />,
    date: '2015',
    content: (
      <Typography>
        Co-author of &#39;
        <Link
          rel='noopener noreferrer'
          target='_blank'
          to={{
            pathname: 'https://ieeexplore.ieee.org/document/7344144',
          }}
        >
          Tendencies Towards DEEP or SURFACE Learning for Participants Taking a
          Large Massive Open Online Course (MOOC)
        </Link>
        &#39; published to IEEE Frontiers in Education Conference (FIE).
      </Typography>
    ),
  },

  {
    title: 'National Service',
    icon: <Svg img={FlagIcon} />,
    date: '2015 - 2017',
    content: (
      <>
        <Typography>
          Completed 2 years of full-time NS as an admin support assistant.
        </Typography>
        <Ul>
          <li>
            <Typography>
              Outstanding Soldier of the Month for April 2016
            </Typography>
          </li>
          <li>
            <Typography>Best Soldier of the Month for December 2016</Typography>
          </li>
          <li>
            <Typography>Webmaster for the unit’s intranet website</Typography>
          </li>
        </Ul>
      </>
    ),
  },

  {
    title: 'Degree - National University of Singapore (NUS)',
    icon: <Svg img={EducationIcon} />,
    date: '2017 - 2020',
    content: (
      <>
        <Typography>
          Graduated from NUS with a Bachelor of Computing (Honours) in Computer
          Science.
        </Typography>
        <Ul>
          <li>
            <Typography>
              Certificate of Merit in Computer Security Focus Area
            </Typography>
          </li>
          <li>
            <Typography>
              Awarded the Honor List of Student Tutors for the Academic Year
              2019/20 for good teaching
            </Typography>
          </li>
          <li>
            <Typography>
              Committee Member for School of Computing Freshmen Social Camp 2018
            </Typography>
          </li>
          <li>
            <Typography>
              Orientation Group Leader for Ridge View Residential College
              Freshmen Orientation Camp 2018
            </Typography>
          </li>
          <li>
            <Typography>
              Publicity Director for Ridge View Residential College Flag Day
              2018
            </Typography>
          </li>
        </Ul>
      </>
    ),
  },

  {
    title: 'First Job',
    icon: <Svg img={BriefcaseIcon} />,
    date: '2020',
    content: <Typography>Associate Full Stack Developer at IBM</Typography>,
  },

  {
    title: 'To Be Continued...',
    icon: <Svg img={MoreIcon} />,
    date: '',
    content: <Typography>...</Typography>,
  },
];
