/* @flow strict */

// import type { ComponentType } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const FullScreen: StyledComponent<Props, Theme, *> = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
