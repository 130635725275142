/* @flow strict */

import * as React from 'react';
import type { ContextRouter } from 'react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Navigation } from 'components/Navigation';
import { AppProvider } from 'context';
import { About, Milestones, Portfolio, NotFound, Services } from 'pages';
import { getPathname } from 'utils/common';
import { Routes } from 'utils/routes';

const routes = [
  { path: Routes.about, Component: About },
  { path: Routes.milestones, Component: Milestones },
  { path: `${Routes.portfolio}/:project?`, Component: Portfolio },
  { path: Routes.services, Component: Services },
  { path: '*', Component: NotFound },
];

export const AppRoutes = (): React.Element<'div'> => {
  const location = useLocation();
  const pathname = getPathname(location.pathname);

  return (
    <div>
      <Route
        component={({ location }: ContextRouter) => (
          <Navigation location={location} />
        )}
      />

      <AppProvider>
        <TransitionGroup>
          <CSSTransition key={pathname} classNames='fade' timeout={300}>
            <Switch location={location}>
              {routes.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                  <div className='page'>
                    <Component />
                  </div>
                </Route>
              ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </AppProvider>
    </div>
  );
};
