/* @flow strict */

import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const Content: StyledComponent<Props, Theme, *> = styled.div`
  .header {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  .section {
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  .section .point {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ol {
    padding-inline-start: ${({ theme }) => theme.spacing(3)};
  }

  .title {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  .section form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    max-width: 800px;

    & > * {
      width: 100%;
    }

    ${({ theme }) => {
      return `
        ${theme.breakpoints.up('md')} {
          flex-direction: row;
          justify-content: space-between;

          & > *:not(.MuiFormControl-fullWidth) {
            width: 25ch;
          }
        }
      `;
    }};
  }
`;

export const Split: StyledComponent<Props, Theme, *> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  & > * {
    width: 100%;
  }

  & > * + img,
  & > img + * {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  img {
    width: 100%;
    height: auto;
  }

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      flex-wrap: nowrap;

      & > * + img,
      & > img + * {
        margin-left: ${theme.spacing(6)};
        padding-top: 0;
        padding-bottom: 0;
      }

      & > img {
        max-width: 500px;
      }
    }
    `;
  }};
`;

export const ButtonWrapper: StyledComponent<Props, Theme, *> = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  position: relative;

  button {
    width: 100%;
    height: 40px;
  }

  .progress {
    position: absolute;
    top: 50%;
    left: 50%;

    /* Because spinner is size 24 */
    margin-top: -12px;
    margin-left: -12px;
  }
`;
