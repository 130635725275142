/* @flow strict */

import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { Container } from 'components/Container';
import { PageHeader } from 'utils/styles';

import { milestones } from './contents';
import {
  Timeline,
  TimelineItem,
  TimelineIcon,
  TimelineContent,
} from './styles';

const CONTAINER_MODULE = 'milestones';

export const Milestones = (): React.Element<typeof Container> => {
  return (
    <Container id={CONTAINER_MODULE}>
      <Helmet>
        <title>Milestones</title>
        <meta content='Key milestones in my life.' name='description' />
      </Helmet>
      <PageHeader>
        <Typography component='h1' variant='h4'>
          Milestones
        </Typography>
        <Typography component='h2'>Key milestones in my life</Typography>
      </PageHeader>

      <Timeline>
        {milestones.map((item, index) => (
          <TimelineItem key={item.title}>
            <TimelineIcon>{item.icon}</TimelineIcon>
            <TimelineContent $right={index % 2 === 1}>
              <Typography className='title' component='h3' variant='h5'>
                {item.title}
              </Typography>
              <Typography className='date'>{item.date}</Typography>

              {item.content}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Container>
  );
};
