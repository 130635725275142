/* @flow strict */

import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Container } from 'components/Container';
import { Routes } from 'utils/routes';

import { FullScreen } from './styles';

const CONTAINER_MODULE = 'not-found';

export const NotFound = (): React.Element<typeof Container> => {
  return (
    <Container id={CONTAINER_MODULE}>
      <FullScreen>
        <Typography className='text'>This page does not exist.</Typography>
        <Link to={Routes.services}>Back to Home</Link>
      </FullScreen>
    </Container>
  );
};
