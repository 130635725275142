/* @flow strict */

import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

type Props = {};

export const GlobalStyle: React.ComponentType<Props> = createGlobalStyle`
  img {
    user-select: none;
  }

  /* Page transition */
  .page {
    position: absolute;
    left: 15px;
    right: 15px;
  }

  .fade-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .fade-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s, transform 0.3s;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    transition: opacity 0.3s, transform 0.3s;
  }
  /* End page transition */

  /* Hamburger menu */
  /* https://codepen.io/designcouch/pen/Atyop */
  .menu-button {
    position: relative;
    display: block;
    padding: 20px; /* to fill up min 64px nav height */
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .menu-icon {
    width: 28px; /* overall width and height of menu icon */
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
  }

  .menu-icon span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 2px; /* thickness of each line */
    width: 100%;
    background: ${({ theme }) => theme.palette.text.primary};
    border-radius: 0;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .menu-icon span:nth-child(1) {
    top: 0px;
  }

  .menu-icon span:nth-child(2), .menu-icon span:nth-child(3) {
    top: 10px; /* gap between each line */
  }

  .menu-icon span:nth-child(4) {
    top: 20px; /* gap between each line */
  }

  .menu-icon.open span:nth-child(1) {
    top: 10px; /* gap between each line */
    width: 0%;
    left: 50%;
  }

  .menu-icon.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  .menu-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .menu-icon.open span:nth-child(4) {
    top: 10px; /* gap between each line */
    width: 0%;
    left: 50%;
  }
  /* End hamburger menu */
`;
