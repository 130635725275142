/* @flow strict */

import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import { Image } from 'components/Image';
import { IMAGE_HEIGHT, IMAGE_WIDTH } from 'pages/About/contents';

import { StyledCard, ImageWrapper, CardSection, StyledLink } from './styles';

export type Props = {
  img: string,
  url: string,
  alt: string,
  title: string,
  subtitle?: string,
  date: string,
  href?: string,
  credentialId?: string,
  className?: string,
};

export const Card = ({
  img,
  url,
  alt,
  title,
  subtitle,
  date,
  href,
  credentialId,
  className,
}: Props): React.Element<typeof StyledCard | typeof StyledLink> => {
  const content = (
    <>
      <ImageWrapper>
        <Image
          alt={alt}
          height={IMAGE_HEIGHT}
          src={img}
          url={url}
          width={IMAGE_WIDTH}
        />
      </ImageWrapper>

      <div>
        <CardSection>
          <Typography component='h6' variant='h6'>
            {title}
          </Typography>
          {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
        </CardSection>

        <CardSection>
          <Typography variant='caption'>{date}</Typography>
        </CardSection>

        {credentialId && (
          <Typography variant='caption'>
            Credential ID: {credentialId}
          </Typography>
        )}
      </div>
    </>
  );

  if (href) {
    return (
      <StyledLink
        className={className}
        rel='noopener noreferrer'
        target='_blank'
        to={{
          pathname: href,
        }}
      >
        <StyledCard>{content}</StyledCard>
      </StyledLink>
    );
  }

  return <StyledCard className={className}>{content}</StyledCard>;
};
