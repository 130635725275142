/* @flow strict */

import * as React from 'react';

import { StyledContainer } from './styles';

type Props = {
  children: React.Node,
  id: string,
};

export const Container = ({
  children,
  id,
}: Props): React.Element<typeof StyledContainer> => {
  return <StyledContainer id={id}>{children}</StyledContainer>;
};
