/* @flow strict */

type ValidRoutes = {
  about: string,
  milestones: string,
  portfolio: string,
  services: string,
};

export const Routes: ValidRoutes = {
  about: '/about',
  milestones: '/milestones',
  portfolio: '/portfolio',
  services: '/',
};
