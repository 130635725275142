/* @flow strict */

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';

import { Container } from 'components/Container';
import { Routes } from 'utils/routes';
import { PageHeader } from 'utils/styles';

import { projects } from './contents';
import {
  CardContainer,
  Card,
  CardLink,
  DialogTitle,
  DialogContent,
  Gallery,
  ProjectLogo,
} from './styles';

const CONTAINER_MODULE = 'portfolio';

export const Portfolio = (): React.Element<typeof Container> => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const defaultPageTitle = 'Portfolio';
  const defaultPageDescription =
    'Browse through past projects. Interested in working together for a project? Let’s get in touch and bring your project to life.';
  const [pageTitle, setPageTitle] = useState(defaultPageTitle);
  const [pageDescription, setPageDescription] = useState(
    defaultPageDescription
  );

  useEffect(() => {
    const projectName = pathname.replace(`${Routes.portfolio}/`, '');
    const project = projects.find(
      (project) => project.shortName === projectName
    );

    if (project) {
      setPageTitle(`${project.title} - ${defaultPageTitle}`);
      setPageDescription(project.metaDescription);
      setOpen(true);
      setSelectedProject(project);
    } else {
      setPageTitle(defaultPageTitle);
      setPageDescription(defaultPageDescription);
    }
  }, [pathname]);

  const handleClose = () => {
    setOpen(false);
    history.push({ pathname: Routes.portfolio });
  };

  return (
    <Container id={CONTAINER_MODULE}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta content={pageDescription} name='description' />
      </Helmet>
      <PageHeader>
        <Typography component='h1' variant='h4'>
          Portfolio
        </Typography>
        <Typography component='h2'>
          Click on the projects to find out more
        </Typography>
      </PageHeader>

      <CardContainer>
        {projects.map((project) => (
          <Card key={project.title} rows={project.rows}>
            <CardLink to={`${Routes.portfolio}/${project.shortName}`}>
              {project.img && (
                <picture>
                  <source srcSet={project.img.webp} type='image/webp' />
                  <source srcSet={project.img.src} type='image/png' />
                  <img
                    alt={project.img.alt}
                    loading='lazy'
                    src={project.img.src}
                  />
                </picture>
              )}
              {project.icon}
              <Typography>{project.title}</Typography>
            </CardLink>
          </Card>
        ))}
      </CardContainer>

      <Dialog
        aria-labelledby='project-title'
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        {selectedProject && (
          <>
            <DialogTitle id='project-title'>
              {selectedProject.logo && (
                <ProjectLogo>
                  <source
                    srcSet={selectedProject.logo.webp}
                    type='image/webp'
                  />
                  <source srcSet={selectedProject.logo.src} type='image/png' />
                  <img
                    alt={selectedProject.logo.alt}
                    loading='lazy'
                    src={selectedProject.logo.src}
                  />
                </ProjectLogo>
              )}
              <Typography component='span' variant='h5'>
                {selectedProject.title}
              </Typography>
            </DialogTitle>
            <DialogContent>
              {selectedProject.date && (
                <Typography className='date'>{selectedProject.date}</Typography>
              )}

              <Typography display='block' variant='body1'>
                {selectedProject.description}
              </Typography>

              {selectedProject.link && (
                <Link
                  rel='noopener noreferrer'
                  target='_blank'
                  to={{
                    pathname: selectedProject.link,
                  }}
                >
                  Visit website
                </Link>
              )}

              {selectedProject.gallery && (
                <>
                  <Typography className='gallery' variant='h6'>
                    Gallery
                  </Typography>
                  <Gallery>
                    {selectedProject.gallery.map((image) => (
                      <div key={image.alt}>
                        {image.type === 'video' ? (
                          <video autoPlay loop muted playsInline>
                            <source src={image.webp} type='video/webm' />
                            <source src={image.src} type='video/mp4' />
                          </video>
                        ) : (
                          <picture>
                            <source srcSet={image.webp} type='image/webp' />
                            <source srcSet={image.src} type='image/png' />
                            <img
                              alt={image.alt}
                              loading='lazy'
                              src={image.src}
                            />
                          </picture>
                        )}

                        {image.caption && (
                          <Typography display='block' variant='caption'>
                            {image.caption}
                          </Typography>
                        )}
                      </div>
                    ))}
                  </Gallery>
                </>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Container>
  );
};
