/* @flow strict */

import { Link } from 'react-router-dom';
import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type EmptyProp = {};

export const SlidingBorder: StyledComponent<EmptyProp, Theme, *> = styled.span`
  position: absolute;
  bottom: 0px;
  display: none;
  width: inherit;
  height: 2px;
  background: ${({ theme }) => theme.palette.primary.light};
  transition: all 0.3s cubic-bezier(0.26, 0.07, 0.49, 1.09);

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
    `;
  }};
`;

export const Nav: StyledComponent<EmptyProp, Theme, *> = styled.nav`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;

  min-height: ${({ theme }) => theme.spacing(8)};
  z-index: ${({ theme }) => theme.zIndex.navBar};
  color: ${({ theme }) => theme.palette.text.primary};
`;

type NavItemsProps = {
  $isVisible: boolean,
};

export const NavItems: StyledComponent<NavItemsProps, Theme, *> = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: right;

  height: 0;
  transform: translate(0, -300px);
  transition: transform 0.3s ease, height 0.3s ease;

  ${({ $isVisible }) => {
    // To adjust height if more/less items are in the navigation
    if ($isVisible) return 'height: 226px; transform: translate(0, 0);';
  }};

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      height: auto;
      transform: translate(0, 0);
      flex-direction: row;
    }
    `;
  }};

  &:hover > ${SlidingBorder} {
    /* Slightly lighter color of primary color */
    background: #4c8af4;
  }
`;

type NavLinkProps = {
  $isActive: boolean,
};

export const NavLink: StyledComponent<NavLinkProps, Theme, *> = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  border-bottom: 2px solid transparent;

  padding: ${({ theme }) => theme.spacing(2)};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight || 'regular'};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ $isActive, theme }) => {
    if ($isActive)
      return `
        font-weight: ${theme.typography.fontWeightMedium};
        color: ${theme.palette.primary.light || ''};

        ${theme.breakpoints.down('sm')} {
          border-right: 2px solid ${theme.palette.primary.light || ''};
        }
        ${theme.breakpoints.up('sm')} {
          border-bottom: 2px solid ${theme.palette.primary.light || ''};
        }
    `;
  }};

  /* Sliding border */
  &:nth-child(1):hover ~ ${SlidingBorder} {
    right: 349px;
    width: 78px;
  }

  &:nth-child(2):hover ~ ${SlidingBorder} {
    right: 223px;
    width: 127px;
  }

  &:nth-child(3):hover ~ ${SlidingBorder} {
    right: 104px;
    width: 119px;
  }

  &:nth-child(4):hover ~ ${SlidingBorder} {
    right: 0;
    width: 103px;
  }
`;

export const MobileNav: StyledComponent<EmptyProp, Theme, *> = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
    `;
  }};
`;
