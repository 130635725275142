/* @flow strict */

import * as Images from 'assets/images/services';
import { getImagePath, type Image } from 'utils/common';

const FOLDER_PATH = 'services';
export const IMAGE_WIDTH = 500;
export const IMAGE_HEIGHT = 300;
const getServicesImagePath = (file: string): string => {
  return getImagePath(FOLDER_PATH, file, IMAGE_WIDTH, IMAGE_HEIGHT);
};

type SellingPoints = {
  title: string,
  description: string,
  img: Image,
};

export const introductionImg: Image = {
  url: getServicesImagePath('coding.jpeg'),
  src: Images.Coding,
  webp: Images.CodingWebP,
  alt: 'Coding',
};

export const closingImg: Image = {
  url: getServicesImagePath('laptop.jpeg'),
  src: Images.Laptop,
  webp: Images.LaptopWebP,
  alt: 'Coding',
};

export const sellingPoints: Array<SellingPoints> = [
  {
    title: 'Responsive Website Design',
    description:
      'As a professional software developer who’s worked at tech giants like Facebook and IBM, I know exactly what your website needs to perform and impress. From my office in Singapore, I’ll help you build your business’s website from scratch using various different coding languages, or I can help you refine what you already have to suit a variety of different devices.',
    img: {
      url: getServicesImagePath('responsive.jpeg'),
      src: Images.Responsive,
      webp: Images.ResponsiveWebP,
      alt: 'Different screen sizes - mobile, tablet, and desktop',
    },
  },
  {
    title: 'Website Analytics',
    description:
      'With my help, you’ll be able to see what works, what doesn’t, and what you can do to ensure that your business, website, or software works perfectly.',
    img: {
      url: getServicesImagePath('analytics.jpeg'),
      src: Images.Analytics,
      webp: Images.AnalyticsWebP,
      alt: 'Notebook and laptop',
    },
  },
  {
    title: 'Search Engine Optimization (SEO)',
    description:
      'From navigating complex sitemaps to building high-quality metadata, I’ll provide you with the technical SEO services your website needs to rank higher on your preferred search engine.',
    img: {
      url: getServicesImagePath('seo.jpeg'),
      src: Images.SEO,
      webp: Images.SEOWebP,
      alt: 'Looking at code through glasses',
    },
  },
  {
    title: 'Usability, Accessibility & Inclusion',
    description:
      'The details make all the difference. My web accessibility services include optimizing your website’s color contrast, screen reader friendliness, and more.',
    img: {
      url: getServicesImagePath('accessibility.jpeg'),
      src: Images.Accessibility,
      webp: Images.AccessibilityWebP,
      alt: 'Photo of iMac and MacBook',
    },
  },
  {
    title: 'Affordable Rates',
    description:
      'As a professional software developer, I understand how expensive software development services can be, but I choose to offer them at affordable, reasonable rates instead. My goal as a freelancer isn’t just to sell my services, but to educate my clients on what they need as a business and how I can help. My software development services start from as little as S$1,500, so reach out to me to find out more.',
    img: {
      url: getServicesImagePath('home-office.jpeg'),
      src: Images.HomeOffice,
      webp: Images.HomeOfficeWebP,
      alt: 'Home office with multiple screens',
    },
  },
];

export const howItWorks = [
  {
    title: 'Reach Out to Me',
    description:
      'You can contact me by completing the contact form at the end of this page. Use it to tell me more about what you need, what your budgetary requirements are, and what you envision for your website or project.',
  },
  {
    title: 'Book A Consultation',
    description:
      'When you book an obligation-free consultation, we’ll identify your needs, goals, and potential obstacles together before I’ll make actionable recommendations on what to do next.',
  },
  {
    title: 'Get The Results You Need',
    description:
      'Once we’ve established how I can help you, I’ll empower you with the insight, tools, and software you need to achieve your business’s goals.',
  },
];
