/* @flow strict */

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { materialTheme } from 'utils/material-theme';
import { theme } from 'utils/theme';

import { AppProvider } from './context';
import { AppRoutes } from './routes';
import { GlobalStyle } from './styles';

const App = (): React.Element<typeof ThemeProvider> => {
  return (
    <ThemeProvider theme={theme}>
      <MaterialThemeProvider theme={materialTheme}>
        <CssBaseline />
        <GlobalStyle />
        <HelmetProvider>
          <Helmet
            defaultTitle='Denzel Chung'
            titleTemplate='%s | Denzel Chung'
          ></Helmet>
          <AppProvider>
            <Router>
              <AppRoutes />
            </Router>
          </AppProvider>
        </HelmetProvider>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
};

export default App;
