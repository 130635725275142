/* @flow strict */

import type { ComponentType } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import Down from 'assets/icons/glyphicons-basic-221-chevron-down.svg';
import Up from 'assets/icons/glyphicons-basic-222-chevron-up.svg';
import type { Theme } from 'utils/theme';

type Props = {};

export const ChevronWrapper: StyledComponent<Props, Theme, *> = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  transform: translateY(-100%);
`;

const ChevronBase: StyledComponent<Props, Theme, *> = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const ChevronDown: ComponentType<Props> = styled(ChevronBase)`
  mask-image: url(${Down});
`;

export const ChevronUp: ComponentType<Props> = styled(ChevronBase)`
  mask-image: url(${Up});
`;
