/* @flow strict */

import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const TimelineItem: StyledComponent<Props, Theme, *> = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  &::before,
  &::after {
    content: '';
    clear: both;
    display: block;
    width: 100%;
  }
`;

export const Timeline: StyledComponent<Props, Theme, *> = styled.div`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing(3)};

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary.light};
    left: 0;
    top: 0;
    position: absolute;
  }

  &::after {
    content: '';
    clear: both;
    display: table;
    width: 100%;
  }

  > ${TimelineItem}:last-child {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      margin-left: 0;
      
      &::before {
        left: 50%;
      }
    }
    `;
  }};
`;

export const TimelineIcon: StyledComponent<Props, Theme, *> = styled.div`
  left: 0;
  background: ${({ theme }) => theme.palette.primary.light};
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  overflow: hidden;
  margin-left: -23px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      left: 50%;
    }
    `;
  }};
`;

type SvgProps = {
  img: string,
};

export const Svg: StyledComponent<SvgProps, Theme, *> = styled.div`
  mask-image: url(${({ img }) => img});
  position: relative;
  width: 24px;
  height: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.default};
`;

type ContentProps = { $right?: boolean };

export const TimelineContent: StyledComponent<
  ContentProps,
  Theme,
  *
> = styled.div`
  --padding-x: ${({ theme }) => theme.spacing(2)};
  --padding-y: ${({ theme }) => theme.spacing(2)};
  --spacing-x-desktop: ${({ theme }) => theme.spacing(3)};
  --spacing-y-desktop: ${({ theme }) => theme.spacing(3)};

  box-sizing: border-box;
  width: 87%;
  float: right;
  background: ${({ theme }) => theme.palette.background.card};
  box-shadow: ${({ theme }) => theme.shadows['1']};
  transition: all 0.3s ease;

  padding-top: var(--padding-y);
  padding-right: var(--padding-x);
  padding-bottom: var(--padding-y);
  padding-left: var(--padding-x);

  &::before {
    content: '';
    position: absolute;
    left: 10%;
    margin-left: 3px;
    top: 20px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 0;
    border-right: 7px solid ${({ theme }) => theme.palette.primary.light};
  }

  .title {
    margin-top: calc(-1 * var(--padding-y));
    margin-right: calc(-1 * var(--padding-x));
    margin-bottom: 0;
    margin-left: calc(-1 * var(--padding-x));
    padding: var(--padding-x);
    background: ${({ theme }) => theme.palette.primary.light};
    color: #fff;
  }

  > .date {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme, $right }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      padding-top: var(--spacing-y-desktop);
      padding-right: var(--spacing-x-desktop);
      padding-bottom: var(--spacing-y-desktop);
      padding-left: var(--spacing-x-desktop);

      width: 45%;
      float: left;

      &::before {
        left: 45%;
        margin-left: 0;
        border-left: 7px solid ${theme.palette.primary.light || '#0066ff'};
        border-right: 0;
      }

      .title {
        margin-top: calc(-1 * var(--spacing-y-desktop));
        margin-right: calc(-1 * var(--spacing-x-desktop));
        margin-bottom: 0;
        margin-left: calc(-1 * var(--spacing-x-desktop));
        padding-right: var(--spacing-x-desktop);
        padding-left: var(--spacing-x-desktop);
      }

      ${
        $right
          ? `
            float: right;

            &::before {
              content: '';
              right: 45%;
              left: inherit;
              border-left: 0;
              border-right: 7px solid ${
                theme.palette.primary.light || '#0066ff'
              };
            }
          `
          : ''
      }
    }
    `;
  }};
`;

export const Ul: StyledComponent<Props, Theme, *> = styled.ul`
  padding-inline-start: 20px;

  > li {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
