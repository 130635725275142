/* @flow strict */

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Container } from 'components/Container';
import { Spinner } from 'components/Spinner';
import { FullScreen } from 'pages';

import { SpinnerWrapper } from './styles';

const CONTAINER_MODULE = 'error';

type ErrorFallbackProps = {
  error: Error,
  // eslint-disable-next-line flowtype/no-weak-types
  resetErrorBoundary: (Array<Event>) => void,
  ...
};

export const ErrorFallback = ({
  // eslint-disable-next-line no-unused-vars
  error,
  resetErrorBoundary,
}: ErrorFallbackProps): React.Element<typeof Container> => {
  return (
    <Container id={CONTAINER_MODULE}>
      <FullScreen>
        <Typography className='text'>Something went wrong.</Typography>
        <Button color='primary' variant='outlined' onClick={resetErrorBoundary}>
          Go back
        </Button>
      </FullScreen>
    </Container>
  );
};

// eslint-disable-next-line no-unused-vars
const errorHandler = (error: Error, info: { componentStack: string }) => {
  // TODO: log error
};

type AppProviderProps = {
  children: React.Node,
};

export const AppProvider = ({
  children,
}: AppProviderProps): React.Element<typeof React.Suspense> => {
  return (
    <React.Suspense
      fallback={
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};
