/* @flow strict */

import type { ComponentType } from 'react';
import styled from 'styled-components';

type Props = {};

export const SpinnerWrapper: ComponentType<Props> = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
