/* @flow strict */

import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import type { Location } from 'react-router';

import { Container } from 'components/Container';
import { useOutsideAlerter, getPathname } from 'utils/common';
import { Routes } from 'utils/routes';

import { Nav, NavItems, NavLink, MobileNav } from './styles';

const CONTAINER_MODULE = 'navigation';

type Props = {
  location: Location,
};

const navLinks = [
  {
    to: Routes.about,
    text: 'ABOUT',
  },
  {
    to: Routes.milestones,
    text: 'MILESTONES',
  },
  {
    to: Routes.portfolio,
    text: 'PORTFOLIO',
  },
  {
    to: Routes.services,
    text: 'SERVICES',
  },
];

export const Navigation = ({ location }: Props): React.Element<typeof Nav> => {
  let currentPath = getPathname(location?.pathname);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const menuIconClass = classNames('menu-icon', { open: isNavOpen });

  // Detect clicks outside navigation
  const { ref, isOutside, setIsOutside } = useOutsideAlerter(true);

  function handleHamburgerClick(): void {
    setIsNavOpen((prevState) => !prevState);
  }

  useEffect(() => {
    if (isNavOpen) {
      // reset
      setIsOutside(false);
    }
  }, [isNavOpen, setIsOutside]);

  useEffect(() => {
    if (isOutside) {
      setIsNavOpen(false);
    }
  }, [isOutside]);

  return (
    <Nav ref={ref}>
      <Container id={CONTAINER_MODULE}>
        <MobileNav>
          {isOutside}
          <div
            aria-label='menu'
            className='menu-button'
            role='button'
            tabIndex={0}
            onClick={handleHamburgerClick}
            onKeyPress={handleHamburgerClick}
          >
            <div className={menuIconClass}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </MobileNav>
        <NavItems $isVisible={isNavOpen}>
          {navLinks.map((link) => (
            <NavLink
              key={link.text}
              $isActive={link.to === currentPath}
              to={link.to}
              onClick={handleHamburgerClick}
            >
              {link.text}
            </NavLink>
          ))}
          {/* <SlidingBorder /> */}
        </NavItems>
      </Container>
    </Nav>
  );
};
