/* @flow strict */

import type { ComponentType } from 'react';
import styled, { type StyledComponent } from 'styled-components';

import { StyledCard } from 'components/Card';
import { PageHeader } from 'utils/styles';
import type { Theme } from 'utils/theme';

type Props = {};

export const MobileLineBreak: StyledComponent<Props, Theme, *> = styled.br`
  display: block;

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
    `;
  }};
`;

const Flex: ComponentType<Props> = styled.div`
  display: flex;
  justify-content: center;
`;

export const IntroductionSection: StyledComponent<Props, Theme, *> = styled(
  Flex
)`
  min-height: calc(100vh - 64px);
  position: relative;

  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;

  & ${PageHeader} {
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Section: ComponentType<Props> = styled(Flex)`
  min-height: 100vh;
  position: relative;

  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const SectionWrapper: StyledComponent<Props, Theme, *> = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(10)};

  & > h2 {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  & > h3 {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  & ${StyledCard}:not(:last-child),
  .card-link:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

/*
 * Social media icons - 32px
 */
export const Icon: StyledComponent<Props, Theme, *> = styled.div`
  width: auto;
  height: 32px;

  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus {
    transform: scale(1.05);
  }
`;

export const IconWrapper: StyledComponent<Props, Theme, *> = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  & > div {
    margin: ${({ theme }) => theme.spacing(3)};
  }

  /* To handle LinkedIn icon - not square, has about 6px extra width */
  /* Margin is derived from original spacing 3: 24px */
  /* 24px - 6px = 18px */
  & > div:last-child {
    margin-right: 18px;
  }
`;

export const Ul: StyledComponent<Props, Theme, *> = styled.ul`
  margin: 0;

  & > li {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
