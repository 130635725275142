/* @flow strict */

import styled, { type StyledComponent } from 'styled-components';

import type { Theme } from 'utils/theme';

type Props = {};

export const StyledContainer: StyledComponent<Props, Theme, *> = styled.div`
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => {
    return `
    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};

      max-width: 1280px;
    }
    `;
  }};
`;
